import { useInsuranceSourceTag } from '@axo/insurance/feature/providers';
import {
  OnEnd,
  StepProviderFinland,
} from '@axo/insurance/feature/providers/fi';
import { ErrorBoundary } from '@axo/shared/feature/error-boundary';
import {
  TranslationProvider,
  addTranslationBundle,
  useTranslation,
  useTranslationContext,
} from '@axo/shared/i18n';
import { AnalyticsProvider } from '@axo/shared/services/analytics';
import { getMixpanelConfig } from '@axo/shared/services/analytics/mixpanel/config/getMixpanelConfig';
import { getLDConfigWithAnonymousUser } from '@axo/services/launch-darkly/config/getLDConfig';
import * as Sentry from '@sentry/react';
import { LDProvider, useLDClient } from 'launchdarkly-react-client-sdk';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import { Trans } from 'react-i18next';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ErrorView } from '../components/ErrorView';
import { insuranceSourceTag } from '../consts/sourceTag';
import fi from '../translations/fi.InsuranceFi.i18n.json';
import './base.css';
import { onInsuranceEnd, onInsuranceError } from './config';
import { Main } from './pages/Main/Main';

const LD_CLIENT_ID = import.meta.env.VITE_LAUNCH_DARKLY_CLIENT;
const LD_CONFIG = getLDConfigWithAnonymousUser({
  clientSideID: LD_CLIENT_ID,
  context: {
    site: {
      kind: 'insurance',
      key: 'ins-fi',
      country: 'fi',
    },
  },
});

!import.meta.env.CI &&
  mixpanel.init(
    import.meta.env.VITE_MIXPANEL_TOKEN as string,
    getMixpanelConfig()
  );

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.MODE,
  enabled: import.meta.env.MODE !== 'development',
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0,

  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

addTranslationBundle({ InsuranceFi: { fi } });

function App() {
  const { t } = useTranslation();
  const { ns: translationNamespace } = useTranslationContext();
  const onEnd: OnEnd = {
    action: onInsuranceEnd.action,
    summaryButtonText: t('Next'),
  };
  const ldClient = useLDClient();

  const source = useInsuranceSourceTag();

  return (
    <React.StrictMode>
      <BrowserRouter>
        <ErrorBoundary
          preventMultipleOnErrorCalls={true}
          fallback={
            <ErrorView
              title={t('Something went wrong')}
              description={
                // TODO: create our custom Trans component wrapper which will automatically inject proper namespace
                <Trans ns={translationNamespace}>
                  Call customer service if the problem persists{' '}
                  <a href="tel:0942452326">09 4245 2326</a>
                </Trans>
              }
            />
          }
        >
          <AnalyticsProvider
            enabledServices={[
              'mixpanel',
              { service: 'launchDarkly', params: { client: ldClient } },
              'clarity',
            ]}
          >
            <Routes>
              <Route
                path="/"
                element={<Main onEnd={onEnd} onError={onInsuranceError} />}
              >
                <Route
                  index
                  element={
                    <StepProviderFinland
                      onEnd={onEnd}
                      onError={onInsuranceError}
                      sourceTag={source ?? insuranceSourceTag}
                    />
                  }
                />
              </Route>
            </Routes>
          </AnalyticsProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </React.StrictMode>
  );
}

export default () => (
  <TranslationProvider language="fi" ns="InsuranceFi">
    <LDProvider {...LD_CONFIG}>
      <App />
    </LDProvider>
  </TranslationProvider>
);
